import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const Candlestick: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100%" }}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-chart-candlestick"
    >
      <path d="M9 5v4" />
      <rect width="4" height="6" x="7" y="9" rx="1" className={secondaryClasses} />
      <path d="M9 15v2" />
      <path d="M17 3v2" />
      <rect width="4" height="8" x="15" y="5" rx="1" className={primaryClasses} />
      <path d="M17 13v3" />
      <path d="M3 3v16a2 2 0 0 0 2 2h16" />
    </svg>
  );
};
