import { type VisualizationType } from "@fscrypto/domain/visualization/v3";
import { PieChartFilledIcon, ScatterChartIcon, ScorecardIcon } from "@fscrypto/ui";
import { cva } from "class-variance-authority";
import { FunctionComponent } from "react";
import { Area } from "./svgs/area";
import { AreaNormalized } from "./svgs/area-normalized";
import { Bar } from "./svgs/bar";
import { BarHorizontal } from "./svgs/bar-horizontal";
import { BarHorizontalNormalized } from "./svgs/bar-horizontal-normalized";
import { BarLine } from "./svgs/bar-line";
import { BarNormalized } from "./svgs/bar-normalized";
import { Candlestick } from "./svgs/candlestick";
import { Histogram } from "./svgs/histogram";
import { Line } from "./svgs/line";
import { Table } from "./svgs/table";

interface VizIconProps {
  type: VisualizationType;
  variant?: "button" | "icon";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  label?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}

export const VizIcon = ({ type, variant, size, label, onClick, isActive = false }: VizIconProps) => {
  const Icon = (icons[type] ?? icons["bar"]).Icon;
  if (variant === "button") {
    return (
      <button className="flex w-full items-center gap-x-2" onClick={onClick}>
        <div className={style({ size, variant })}>
          <Icon isActive={isActive} />
        </div>
        {label && (
          <div className="text-muted-foreground ml-2 p-1 text-center text-sm font-semibold">{icons[type].label}</div>
        )}
      </button>
    );
  }
  return (
    <div className={style({ size, variant })}>
      <Icon isActive={isActive} />
    </div>
  );
};

type IconsTypes = {
  [key in VisualizationType]: {
    Icon: FunctionComponent<ChartProps>;
    label: string;
  };
};

type ChartProps = {
  primary?: string;
  secondary?: string;
  isActive?: boolean;
};

const icons: IconsTypes = {
  bar: {
    Icon: Bar,
    label: "Bar",
  },
  "bar-line": {
    Icon: BarLine,
    label: "Bar Line",
  },
  "bar-normalized": {
    Icon: BarNormalized,
    label: "Bar Full",
  },
  "bar-stacked": {
    Icon: Bar,
    label: "Bar Stacked",
  },
  "bar-horizontal": {
    Icon: BarHorizontal,
    label: "Horizontal Bar",
  },
  "bar-horizontal-stacked": {
    Icon: BarHorizontal,
    label: "Bar Horizontal Stacked",
  },
  "bar-horizontal-normalized": {
    Icon: BarHorizontalNormalized,
    label: "Horizontal Full",
  },
  candlestick: {
    Icon: Candlestick,
    label: "Candlestick",
  },
  line: {
    Icon: Line,
    label: "Line",
  },
  area: {
    Icon: Area,
    label: "Area",
  },
  "area-normalized": {
    Icon: AreaNormalized,
    label: "Area Full",
  },
  "area-stacked": {
    Icon: Area,
    label: "Area Stacked",
  },
  pie: {
    Icon: () => <PieChartFilledIcon />,
    label: "Pie",
  },
  scatter: {
    Icon: () => <ScatterChartIcon />,
    label: "Scatter",
  },
  "viz-table": {
    Icon: Table,
    label: "Table",
  },
  "big-number": {
    Icon: () => <ScorecardIcon />,
    label: "Big Number",
  },
  histogram: {
    Icon: Histogram,
    label: "Histogram",
  },
};

const style = cva(`flex items-center rounded flex-shrink-0`, {
  variants: {
    size: {
      xs: `w-9 h-6 px-2`,
      sm: "w-12 h-8 p-2",
      md: `w-[60px] h-[40px] p-2`,
      lg: `w-24 h-16 p-2`,
      xl: `w-[120px] h-20 p-2`,
      xxl: `w-36 h-24 p-2`,
    },
    variant: {
      button: `bg-muted border shadow hover:bg-accent`,
      icon: ``,
    },
  },
  defaultVariants: {
    size: "md",
  },
});
