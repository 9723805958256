import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const Histogram: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });
  return (
    <svg
      viewBox="2 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <path
        d="M 12 6 C 12 5.449219 11.550781 5 11 5 C 10.449219 5 10 5.449219 10 6 L 10 16 C 10 16.550781 10.449219 17 11 17 C 11.550781 17 12 16.550781 12 16 Z M 9 9 C 9 8.449219 8.550781 8 8 8 C 7.449219 8 7 8.449219 7 9 L 7 16 C 7 16.550781 7.449219 17 8 17 C 8.550781 17 9 16.550781 9 16 Z M 15 9 C 15 8.449219 14.550781 8 14 8 C 13.449219 8 13 8.449219 13 9 L 13 16 C 13 16.550781 13.449219 17 14 17 C 14.550781 17 15 16.550781 15 16 Z M 18 13 C 18 12.449219 17.550781 12 17 12 C 16.449219 12 16 12.449219 16 13 L 16 16 C 16 16.550781 16.449219 17 17 17 C 17.550781 17 18 16.550781 18 16 Z M 6 15 C 6 14.449219 5.550781 14 5 14 C 4.449219 14 4 14.449219 4 15 L 4 16 C 4 16.550781 4.449219 17 5 17 C 5.550781 17 6 16.550781 6 16 Z M 21 15 C 21 14.449219 20.550781 14 20 14 C 19.449219 14 19 14.449219 19 15 L 19 16 C 19 16.550781 19.449219 17 20 17 C 20.550781 17 21 16.550781 21 16 Z M 4 18 C 3.449219 18 3 18.449219 3 19 C 3 19.550781 3.449219 20 4 20 L 21 20 C 21.550781 20 22 19.550781 22 19 C 22 18.449219 21.550781 18 21 18 Z M 4 18 "
        className={primaryClasses}
      />
      <path
        d="M 12 6 C 12 5.449219 11.550781 5 11 5 C 10.449219 5 10 5.449219 10 6 L 10 16 C 10 16.550781 10.449219 17 11 17 C 11.550781 17 12 16.550781 12 16 Z M 9 9 C 9 8.449219 8.550781 8 8 8 C 7.449219 8 7 8.449219 7 9 L 7 16 C 7 16.550781 7.449219 17 8 17 C 8.550781 17 9 16.550781 9 16 Z M 15 9 C 15 8.449219 14.550781 8 14 8 C 13.449219 8 13 8.449219 13 9 L 13 16 C 13 16.550781 13.449219 17 14 17 C 14.550781 17 15 16.550781 15 16 Z M 18 13 C 18 12.449219 17.550781 12 17 12 C 16.449219 12 16 12.449219 16 13 L 16 16 C 16 16.550781 16.449219 17 17 17 C 17.550781 17 18 16.550781 18 16 Z M 6 15 C 6 14.449219 5.550781 14 5 14 C 4.449219 14 4 14.449219 4 15 L 4 16 C 4 16.550781 4.449219 17 5 17 C 5.550781 17 6 16.550781 6 16 Z M 21 15 C 21 14.449219 20.550781 14 20 14 C 19.449219 14 19 14.449219 19 15 L 19 16 C 19 16.550781 19.449219 17 20 17 C 20.550781 17 21 16.550781 21 16 Z M 4 18 C 3.449219 18 3 18.449219 3 19 C 3 19.550781 3.449219 20 4 20 L 21 20 C 21.550781 20 22 19.550781 22 19 C 22 18.449219 21.550781 18 21 18 Z M 4 18 "
        className={secondaryClasses}
      />
    </svg>
  );
};
