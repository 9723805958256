import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const Bar: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });
  return (
    <svg viewBox="0 0 90 60" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "100%" }}>
      {/* Rectangles with secondary color */}
      <rect x="78.75" y="18.75" width="11.25" height="41.25" rx="2" ry="2" className={secondaryClasses} />
      <rect x="26.25" y="37.5" width="11.25" height="22.5" rx="2" ry="2" className={secondaryClasses} />
      <rect x="52.5" y="45" width="11.25" height="15" rx="2" ry="2" className={secondaryClasses} />
      <rect x="0" y="18.75" width="11.25" height="41.25" rx="2" ry="2" className={secondaryClasses} />
      {/* Rectangles with primary color */}
      <rect x="78.75" width="11.25" height="15" rx="2" ry="2" className={primaryClasses} />
      <rect x="26.25" y="11.25" width="11.25" height="22.5" rx="2" ry="2" className={primaryClasses} />
      <rect x="52.5" y="30" width="11.25" height="11.25" rx="2" ry="2" className={primaryClasses} />
      <rect x="0" y="3.96" width="11.25" height="11.25" rx="2" ry="2" className={primaryClasses} />
    </svg>
  );
};
