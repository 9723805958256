import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const BarHorizontal: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg viewBox="0 0 89 60" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "100%" }}>
      {/* Secondary colored bars */}
      <rect width="53.44" height="12" rx="2" ry="2" className={secondaryClasses} />
      <rect y="24" width="32" height="12" rx="2" ry="2" className={secondaryClasses} />
      <rect y="48" width="64.99" height="12" rx="2" ry="2" className={secondaryClasses} />
      {/* Primary colored bars */}
      <rect x="57" width="20" height="12" rx="2" ry="2" className={primaryClasses} />
      <rect x="36" y="24" width="16" height="12" rx="2" ry="2" className={primaryClasses} />
      <rect x="69" y="48" width="20" height="12" rx="2" ry="2" className={primaryClasses} />
    </svg>
  );
};
