import Highcharts from "highcharts";
import HighchartsReactOriginal from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Accessibility from "highcharts/modules/accessibility";
import HighchartsExporting from "highcharts/modules/exporting";
import Histogram from "highcharts/modules/histogram-bellcurve";
import Stocks from "highcharts/modules/stock";
import baseTheme from "../themes/base-theme";

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "Inter Variable",
    },
  },
  time: {
    useUTC: false,
  },
  credits: {
    enabled: false,
  },
  lang: {
    thousandsSep: ",",
  },
});
Highcharts.setOptions(baseTheme);
Accessibility(Highcharts);
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
Histogram(Highcharts);
Stocks(Highcharts);

const HighchartsReact = (props: HighchartsReactOriginal.Props) => {
  return (
    <HighchartsReactOriginal
      {...props}
      highcharts={Highcharts}
      containerProps={{ className: "h-full w-full highcharts-dark" }}
    />
  );
};

export { Highcharts, HighchartsReact };
