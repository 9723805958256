import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const BarNormalized: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg viewBox="0 0 90 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "100%" }}>
      {/* Rectangles with secondary color */}
      <rect y="22.5" width="11.25" height="37.5" rx="2" ry="2" className={secondaryClasses} />
      <rect x="78.75" y="18.75" width="11.25" height="41.25" rx="2" ry="2" className={secondaryClasses} />
      <rect x="26.25" y="41.25" width="11.25" height="18.75" rx="2" ry="2" className={secondaryClasses} />
      <rect x="52.5" y="30" width="11.25" height="30" rx="2" ry="2" className={secondaryClasses} />
      {/* Rectangles with primary color */}
      <rect width="11.25" height="18.75" rx="2" ry="2" className={primaryClasses} />
      <rect x="78.75" width="11.25" height="15" rx="2" ry="2" className={primaryClasses} />
      <rect x="26.25" width="11.25" height="37.5" rx="2" ry="2" className={primaryClasses} />
      <rect x="52.5" width="11.25" height="26.25" rx="2" ry="2" className={primaryClasses} />
    </svg>
  );
};
