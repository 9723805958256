import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const Table: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg viewBox="0 0 90 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "100%" }}>
      <rect className={primaryClasses} width="88.4" height="8.84" rx="2" ry="2" />
      <rect className={secondaryClasses} y="16.79" width="23.57" height="10" rx="2" ry="2" />
      <rect className={secondaryClasses} x="27.52" y="16.79" width="61.88" height="10" rx="2" ry="2" />
      <rect className={secondaryClasses} y="33.52" width="23.57" height="10" rx="2" ry="2" />
      <rect className={secondaryClasses} x="26.52" y="33.52" width="61.88" height="10" rx="2" ry="2" />
      <rect className={secondaryClasses} y="51.25" width="23.57" height="10" rx="2" ry="2" />
      <rect className={secondaryClasses} x="26.52" y="51.25" width="61.88" height="10" rx="2" ry="2" />
    </svg>
  );
};
