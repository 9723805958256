import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const BarLine: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg
      viewBox="0 0 86.18 59.38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100%" }}
    >
      <rect x="76.38" y="44.32" width="9.8" height="15.06" rx="2" ry="2" className={secondaryClasses} />
      <rect x="21.03" y="36.79" width="9.8" height="22.59" rx="2" ry="2" className={secondaryClasses} />
      <rect x="40.64" y="36.79" width="9.8" height="22.59" rx="2" ry="2" className={secondaryClasses} />
      <rect x="60.24" y="25.49" width="9.8" height="33.89" rx="2" ry="2" className={secondaryClasses} />
      <rect y="14.2" width="9.8" height="45.19" rx="2" ry="2" className={secondaryClasses} />
      <path
        d="M42.06,28.19L85.2,4.8c.67-.36.96-1.18.67-1.89l-.81-1.98c-.34-.83-1.32-1.18-2.1-.75l-40.86,22.16c-.45.25-1,.24-1.45-.01l-9.54-5.4c-.4-.23-.89-.26-1.32-.08L.92,28.89c-.72.3-1.09,1.09-.85,1.83l.65,2.08c.26.84,1.19,1.27,2.01.94l26.79-11.17c.43-.18.91-.15,1.32.08l9.77,5.53c.45.25,1,.26,1.45.01Z"
        className={primaryClasses}
      />
    </svg>
  );
};
