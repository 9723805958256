import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const Line: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg viewBox="0 0 90 55" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "100%" }}>
      <path
        d="M39.06,18.9l23.18,21.93c.96.91,2.51.65,3.14-.51L85.37,3.06c.52-.97.16-2.19-.82-2.71l-.22-.12c-.97-.52-2.19-.16-2.71.82l-17.37,32.36c-.62,1.16-2.18,1.41-3.14.51l-22.42-21.21c-.82-.78-2.12-.72-2.87.13L1.72,51.28c-.75.84-.65,2.14.21,2.86l.19.16c.83.69,2.06.6,2.78-.2l31.3-35.08c.75-.84,2.05-.9,2.87-.12Z"
        className={primaryClasses}
      />
      <rect
        x="-.95"
        y="27.28"
        width="91.12"
        height="4.79"
        rx="2"
        ry="2"
        transform="translate(-5.36 10.36) rotate(-12.5)"
        className={secondaryClasses}
      />
    </svg>
  );
};
