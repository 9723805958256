import { DateTime } from "luxon";

export function getDateTimeDurationFromDates(start: Date, end: Date): string {
  const e = DateTime.fromJSDate(end);
  const s = DateTime.fromJSDate(start);
  const duration = e.diff(s, "seconds");
  const durationStr = duration.toFormat("s's'");
  if (durationStr === "Invalid Duration") {
    return getDateTimeDurationFromStrings(`${start}`, `${end}`);
  }
  return durationStr;
}

export function getDateTimeDurationFromStrings(start: string, end: string) {
  const e = DateTime.fromISO(end);
  const s = DateTime.fromISO(start);
  return e.diff(s).shiftTo("seconds").toFormat("s's");
}

export function getObjectSizeAsString(obj: any) {
  let str = null;
  if (typeof obj === "string") {
    // If obj is a string, then use it
    str = obj;
  } else {
    // Else, make obj into a string
    str = JSON.stringify(obj);
  }
  // Get the length of the Uint8Array
  const bytes = new TextEncoder().encode(str).length;

  if (bytes < 1024) return bytes + "B";
  else if (bytes < 1048576) return (bytes / 1024).toFixed(0) + "KB";
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(0) + "MB";
  else return (bytes / 1073741824).toFixed(0) + "GB";
}
