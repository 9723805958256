import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const Area: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg
      viewBox="0 0 89.52 57.07"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {/* cls-2 paths, which we're using primaryColor for */}
      <path
        d="M48.9,40.73l10.57-11.37c.36-.38.94-.43,1.35-.1l27.03,21.4c.66.52,1.62.05,1.62-.78-.02-3.92.04-12.14.03-14.25,0-.32-.15-.61-.41-.79-7.35-5.34-23.8-19.18-27.31-22.14-.38-.32-.94-.31-1.31.02-2.35,2.14-10.47,9.53-13.51,12.68-.33.35-.86.41-1.26.14L17.86,7.43c-1-.65-2.09.68-1.26,1.54l30.86,31.78c.4.41,1.06.4,1.45-.02Z"
        className={primaryClasses}
      />
      {/* cls-1 paths, which we're using secondaryColor for */}
      <path
        d="M59.16,35l-9.49,10.2c-.78.84-2.1.85-2.9.03L3.43.61C2.18-.68,0,.21,0,2v47.24s0,5.82,0,5.82c0,1.1.9,2,2,2h4.64s77.61,0,77.61,0c1.9,0,2.73-2.39,1.24-3.57l-23.63-18.71c-.82-.65-2-.56-2.71.21Z"
        className={secondaryClasses}
      />
    </svg>
  );
};
