import { profile } from "@fscrypto/domain";
import { createStore, useStore } from "@fscrypto/state-management";
import { useEffect } from "react";

const profilesStore = createStore<Record<string, profile.Profile>>({});
const publicProfilesStore = createStore<Record<string, profile.ProfilePublic>>({});

export const useProfile = (profileId?: string) => {
  const profiles = useStore(profilesStore);
  if (!profileId) return;
  const profile = profiles[profileId];
  return profile;
};

export const useProfiles = () => {
  const profiles = useStore(profilesStore);

  const addProfile = (profile: profile.Profile) => {
    profilesStore.set({ ...profiles, [profile.id]: profile });
  };

  return {
    profiles,
    addProfile,
  };
};

export const usePublicProfile = (profileId?: string) => {
  const profiles = useStore(publicProfilesStore);
  if (!profileId) return;
  const profile = profiles[profileId];

  return profile;
};

export const usePublicProfiles = (config?: { data: profile.ProfilePublic[] }) => {
  const profiles = useStore(publicProfilesStore);

  useEffect(() => {
    if (config?.data) {
      const mergedProfiles = { ...profiles, ...Object.fromEntries(config.data.map((p) => [p.id, p])) };
      publicProfilesStore.set(mergedProfiles);
    }
  }, [config?.data]);

  const addProfile = (profile: profile.ProfilePublic) => {
    publicProfilesStore.set({ ...profiles, [profile.id]: profile });
  };

  return {
    profiles,
    addProfile,
  };
};
