import { queryRun } from "@fscrypto/domain";
import { OnChangeFn, SortingState, Table, TableState } from "@fscrypto/table";
import clsx from "clsx";
import { memo, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { generateTableColumns } from "./generate-table-columns";
import { QueryTableFooter } from "./table-footer";
import { getDateTimeDurationFromDates } from "./util";

interface TableProps {
  results: queryRun.QueryRunResult;
  dashboardView?: boolean;
  previewView?: boolean;
  toggleTransposed?: () => void;
  onSortingChange?: OnChangeFn<SortingState>;
  sorting?: SortingState;
  tableId: string;
  filter?: string;
  onFilterChange?: (value: string) => void;
  pageIndex?: number;
  onPageIndexChange?: (newPage: number) => void;
  theme: "light" | "dark";
}

const ResultsTableComponent = ({
  results,
  tableId,
  dashboardView,
  previewView,
  sorting = [],
  onSortingChange,
  filter,
  onFilterChange,
  pageIndex,
  onPageIndexChange,
  theme,
}: TableProps) => {
  const [isTransposed] = useState(false);
  const columns = useMemo(() => generateTableColumns(results, isTransposed), [results, isTransposed]);
  const startedAt = results.startedAt ? new Date(results.startedAt) : new Date();
  const endedAt = results.endedAt ? new Date(results.endedAt) : new Date();

  const duration = results.startedAt && results.endedAt ? getDateTimeDurationFromDates(startedAt, endedAt) : "";
  return (
    <ViewContainer>
      <ErrorBoundary FallbackComponent={ErrorResults}>
        <Table
          tableId={tableId}
          columns={columns}
          data={results.csvData}
          paginate
          initialPageSize={20}
          dashboardView={dashboardView}
          previewView={previewView}
          transpose={isTransposed}
          onSortingChange={onSortingChange}
          sorting={sorting}
          filter={filter}
          onFilterChange={onFilterChange}
          onPageIndexChange={onPageIndexChange}
          pageIndex={pageIndex}
          customFooter={(table: TableState<any>) => (
            <QueryTableFooter
              table={table}
              duration={duration}
              results={results}
              filter={filter ?? ""}
              setFilter={onFilterChange ?? (() => {})}
              theme={theme}
            />
          )}
        />
      </ErrorBoundary>
    </ViewContainer>
  );
};

export const ResultsTable = memo(ResultsTableComponent);

const ErrorResults = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      Something went wrong while rendering your results
    </div>
  );
};

const ViewContainer = ({ children, dashboardView }: { children: React.ReactNode; dashboardView?: boolean }) => {
  return (
    <div
      className={clsx("w-full max-w-full overflow-hidden", {
        "h-[calc(100%_-_0px)]": !dashboardView,
        "h-[calc(100%_-_26px)]": dashboardView,
      })}
    >
      {children}
    </div>
  );
};
