/* eslint-disable react-refresh/only-export-components */
import { createColumnHelper } from "@fscrypto/table";
import { isObject, truncate } from "lodash-es";
import { HoverCard } from "@fscrypto/ui";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { MouseEvent, useState } from "react";
import { queryRun } from "@fscrypto/domain";

// Assuming JsonResults, JsonResultsTranspose, and LongString are defined elsewhere

export function generateTableColumns(results: queryRun.QueryRunResult, transposeMode: boolean) {
  return [
    createColumnHelper<number>().display({
      cell: (t: { row: { index: number } }) => <div className="text-center">{t.row.index + 1}</div>,
      id: "row_num",
      enableSorting: false,
      maxSize: 45,
      size: 45,
    }),
    ...results.columns.map((column, i) =>
      createColumnHelper<any>().accessor(
        (d: any[]) => {
          if (typeof d[i] === "object") {
            return JSON.stringify(d[i]);
          }
          return d[i];
        },
        {
          header: column,
          cell: (props: { row: { original: any[] }; getValue: () => any }) => {
            const originalValue = props.row.original[i];
            if (
              ["variant", "json", "object", "array", "binary"].includes(results.types[i] as string) ||
              isObject(originalValue)
            ) {
              return transposeMode ? (
                <JsonResultsTranspose data={originalValue} />
              ) : (
                <JsonResults data={originalValue} type={results.types[i] as string} />
              );
            } else if (results.types[i] === "boolean") {
              return props.getValue() ? "true" : "false";
            } else {
              return <LongString data={originalValue} />;
            }
          },
        },
      ),
    ),
  ];
}

export function isValidURL(data: string) {
  try {
    return Boolean(new URL(data));
  } catch (e) {
    return false;
  }
}

export const JsonResultsTranspose = ({ data }: { data: object }) => {
  // const { notify } = useToaster();
  const copyClipboard = async (e: MouseEvent<HTMLElement>) => {
    const data = (e.target as HTMLElement).textContent;
    await navigator.clipboard.writeText(data ?? "");
    // notify?.({ type: "success", title: "Copied to clipboard", timeout: 1000 });
  };

  // check for object, as non-objects can be returned via the variant type
  if (typeof data === "object" && data !== null) {
    return (
      <HoverCard.Root openDelay={300}>
        <HoverCard.Trigger>
          <div className={"hover:bg-gray-10"}>
            <span>{truncate(JSON.stringify(data), { length: 75 })}</span>
          </div>
        </HoverCard.Trigger>
        <HoverCard.Content side="bottom" sideOffset={10} align="start">
          <p className="text-content/60 whitespace-pre p-2 text-sm font-normal shadow" onClick={copyClipboard}>
            {JSON.stringify(data, null, 2)}
          </p>
        </HoverCard.Content>
      </HoverCard.Root>
    );
  } else {
    return <div>{data}</div>;
  }
};

export const JsonResults = ({ data, type }: { data: object; type: string }) => {
  const [expanded, setExpanded] = useState(false);

  if (type === "array") {
    return <div>{JSON.stringify(data, undefined, 2)}</div>;
  } else {
    // check for object, as non-objects can be returned via the variant type
    if (typeof data === "object" && data !== null) {
      return (
        <AnimatePresence>
          {expanded ? (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
            >
              <div className={"items-top flex gap-x-1"}>
                <ChevronUpIcon className={"h-5 w-5 cursor-pointer  text-gray-50"} onClick={() => setExpanded(false)} />
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </div>
            </motion.div>
          ) : (
            <div className={"items-top flex gap-x-1"}>
              <ChevronDownIcon className={" h-5 w-5 cursor-pointer  text-gray-50"} onClick={() => setExpanded(true)} />
              <span>{truncate(JSON.stringify(data), { length: 75 })}</span>
            </div>
          )}
        </AnimatePresence>
      );
    } else {
      return <div>{data}</div>;
    }
  }
};

export const LongString = ({ data }: { data: string }) => {
  // const { notify } = useToaster();
  const copyClipboard = async (e: MouseEvent<HTMLElement>) => {
    const data = (e.target as HTMLElement).textContent;
    await navigator.clipboard.writeText(data ?? "");
    // notify?.({ type: "success", title: "Copied to clipboard", timeout: 1000 });
  };

  if (data == null) return null;
  if (data.length > 75) {
    return (
      <HoverCard.Root openDelay={300}>
        <HoverCard.Trigger>
          <div className={"hover:bg-gray-10"}>
            <span>{truncate(data, { length: 75 })}</span>
          </div>
        </HoverCard.Trigger>
        <HoverCard.Content side="bottom" sideOffset={10} align="start">
          <p
            className="text-content/60 min-h-min max-w-xl break-words p-2 text-sm font-normal shadow"
            onClick={copyClipboard}
          >
            {isValidURL(data) ? (
              <a target="_blank" href={data} rel="noreferrer" className="underline">
                {data}
              </a>
            ) : (
              <>{data}</>
            )}
          </p>
        </HoverCard.Content>
      </HoverCard.Root>
    );
  } else {
    return isValidURL(data) ? (
      <a target="_blank" href={data} rel="noreferrer" className="underline">
        {data}
      </a>
    ) : (
      <span>{data}</span>
    );
  }
};
