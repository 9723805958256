import clsx from "clsx";

interface SVGComponentProps {
  isActive?: boolean;
}

export const AreaNormalized: React.FC<SVGComponentProps> = ({ isActive = false }) => {
  const primaryClasses = clsx({
    "fill-primary/50": isActive,
    "fill-muted-foreground/70": !isActive,
  });
  const secondaryClasses = clsx({
    "fill-primary/80": isActive,
    "fill-muted-foreground": !isActive,
  });

  return (
    <svg
      viewBox="0 0 89.52 57.07"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {/* Path with secondary color (cls-1) */}
      <path
        d="M59.48,35.19l-9.54,10.26c-.78.84-2.11.86-2.91.03L3.45.61C2.2-.68,0,.21,0,2.01v47.5s0,5.86,0,5.86c0,1.11.9,2.01,2.01,2.01h4.67s78.03,0,78.03,0c1.91,0,2.74-2.4,1.25-3.59l-23.75-18.81c-.82-.65-2.01-.56-2.72.21Z"
        className={secondaryClasses}
      />
      {/* Path with primary color (cls-2) */}
      <path
        d="M88,.55L12.47.03c-1.81-.01-2.71,2.18-1.42,3.45l5.64,5.54,30.29,31.19c.8.83,2.13.81,2.91-.03l9.28-9.97c.71-.77,1.9-.86,2.72-.21l24.82,19.65c1.32,1.04,3.26.11,3.26-1.57,0-4.25.04-10.45.04-12.25,0-.09-.04-.17-.06-.25l.06.02V2.56c0-1.11-.89-2-2-2.01Z"
        className={primaryClasses}
      />
    </svg>
  );
};
