import { useEntity, useOptionalStore } from "@fscrypto/state-management";
import { pluckFirst, useObservable, useObservableState } from "observable-hooks";
import { useEffect } from "react";
import { combineLatest, of, switchMap } from "rxjs";
import { v2VisualizationFactory } from "../state/v2-visualization";
import { visualizationFactory } from "../state/visualization";

export const useVisualization = (id: string) => {
  const entity = useEntity(visualizationFactory, id);
  const value = useOptionalStore(entity?.store);
  if (!value || !entity) {
    return null;
  }
  return {
    value,
    update: entity.update.bind(entity),
    delete: entity.delete.bind(entity),
    get: entity.get.bind(entity),
  };
};

export const useV2Visualization = (id: string) => {
  const entity = useEntity(v2VisualizationFactory, id);
  const value = useOptionalStore(entity?.store);
  if (!value || !entity) {
    return null;
  }
  return {
    value,
    update: entity.update.bind(entity),
    delete: entity.delete.bind(entity),
  };
};

export const useVisualizationAnyVersion = (id: string) => {
  const entityV2 = useEntity(v2VisualizationFactory, id);
  const entityV3 = useEntity(visualizationFactory, id);
  const valueV2 = useOptionalStore(entityV2?.store);
  const valueV3 = useOptionalStore(entityV3?.store);
  if (!entityV3 && !entityV2) {
    return null;
  }
  if (entityV2 && valueV2) {
    return {
      value: valueV2,
      update: entityV2.update.bind(entityV2),
      delete: entityV2.delete.bind(entityV2),
      updateTitle: entityV2.updateTitle.bind(entityV2),
    };
  }

  if (entityV3 && valueV3) {
    return {
      value: valueV3,
      update: entityV3.update.bind(entityV3),
      delete: entityV3.delete.bind(entityV3),
      updateTitle: entityV3.updateTitle.bind(entityV3),
    };
  }
  return null;
};

export const useVisualizations = (queryId: string) => {
  const queryId$ = useObservable(pluckFirst, [queryId]);
  const visualizations$ = useObservable(() => queryId$.pipe(switchMap((id) => visualizationFactory.fromQueryId$(id))));
  const values$ = useObservable(() =>
    visualizations$.pipe(switchMap((v) => (v.length === 0 ? of([]) : combineLatest(v.map((i) => i.store.value$))))),
  );
  const values = useObservableState(values$, []);
  return values;
};

export const useV2Visualizations = (queryId: string) => {
  const queryId$ = useObservable(pluckFirst, [queryId]);
  const visualizations$ = useObservable(() =>
    queryId$.pipe(switchMap((id) => v2VisualizationFactory.fromQueryId$(id))),
  );
  const values$ = useObservable(() =>
    visualizations$.pipe(switchMap((v) => (v.length === 0 ? of([]) : combineLatest(v.map((i) => i.store.value$))))),
  );
  const values = useObservableState(values$, []);
  return values;
};

export const useVisualizationConfig = (id: string) => {
  const viz = useEntity(visualizationFactory, id);
  const config = useOptionalStore(viz?.store, (s) => s.config);
  return config;
};

export const useVisualizationName = (id: string) => {
  const viz = useEntity(visualizationFactory, id);
  const name = useOptionalStore(viz?.store, (s) => s.config.options?.title?.text);
  return name;
};

export const useVisualizationOwner = (id: string) => {
  const viz = useEntity(visualizationFactory, id);
  const owner = useOptionalStore(viz?.ownerStore);
  useEffect(() => {
    viz?.getOwner();
  });
  return owner;
};
