import { merge } from "lodash-es";
import baseTheme from "./base-theme";

const darkTheme = merge({}, baseTheme, {
  chart: {
    backgroundColor: "#1A202C",
  },
  title: {
    style: {
      color: "#fafafa",
    },
  },
  subtitle: {
    style: {
      color: "#fafafa",
    },
  },
  xAxis: {
    title: {
      style: {
        color: "#fafafa",
      },
    },
    labels: {
      style: {
        color: "#fafafa",
      },
    },
  },
  yAxis: {
    title: {
      style: {
        color: "#fafafa",
      },
    },
    labels: {
      style: {
        color: "#fafafa",
      },
    },
    gridLineColor: "#666",
  },
  legend: {
    itemStyle: {
      color: "#fff",
    },
  },
  plotOptions: {
    column: {
      borderColor: "#333",
    },
    bar: {
      borderColor: "#333",
    },
    series: {
      dataLabels: {
        // dark theme --color-contrast
        // HSL 0 0% 100%
        color: "#ffffff",
        style: {
          fontSize: "13px",
        },
      },
      marker: {
        lineColor: "#333",
      },
    },
  },
  tooltip: {
    backgroundColor: "#000",
    style: {
      color: "#fafafa",
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        symbolStroke: "#fff",
        theme: {
          fill: "#1A202C",
        },
      },
    },
  },
} satisfies Highcharts.Options);

export default darkTheme;
