import { Avatar, Button, ConfirmDialog, Dropdown, Input, Text } from "@fscrypto/ui";

import { visualization } from "@fscrypto/domain";
import { getV3TypeFromLegacyChartType } from "@fscrypto/domain/visualization/v3";
import { VizIcon } from "@fscrypto/viz-2";
import { Link, useNavigate } from "@remix-run/react";
import { CopyIcon, MoreVerticalIcon, TrashIcon } from "lucide-react";
import { ReactNode } from "react";
import { $path } from "remix-routes";
import { useDebouncedCallback } from "use-debounce";
import { FileTreeIcon } from "~/features/file-explorer/components/file-explorer-row/file-tree-icon";
import { useProfile } from "~/state/entities/profile";
import { tracking } from "~/utils/tracking";
import { useV2Visualization, useVisualization } from "../hooks";
import { v2VisualizationFactory } from "../state/v2-visualization";
import { visualizationFactory } from "../state/visualization";

const Root = ({ leftSlot, rightSlot }: { leftSlot: ReactNode; rightSlot: ReactNode }) => {
  return (
    <div className="bg-background flex h-14 items-center border-y px-2" data-testid="query-toolbar">
      <div className="mr-4 flex flex-1 items-center">{leftSlot}</div>
      <div className="flex items-center gap-x-2">{rightSlot}</div>
    </div>
  );
};

const Title = ({ disabled = false, visId }: { disabled?: boolean; visId: string }) => {
  const viz = useVisualization(visId);
  const title = viz?.value.config.options.title?.text ?? "Untitled";
  const debouncedTracking = useDebouncedCallback(() => tracking("editor_chart_rename", "Dashboard Beta"), 250);

  if (!viz) return null;

  if (disabled)
    return (
      <Text size="lg" weight={"semi"}>
        {title}
      </Text>
    );

  return (
    <div className="my-1 flex-1">
      <Input
        disabled={disabled}
        value={title}
        inputClassName="border-none hover:ring-border hover:ring-1 shadow-none hover:shadow text-lg font-semibold max-w-[600px]"
        onChange={(e) => {
          const newViz = visualization.v3.updateTitle(viz.value, e.target.value);
          viz.update(newViz);
          debouncedTracking();
        }}
      />
    </div>
  );
};

const V2Title = ({ disabled = false, visId }: { disabled?: boolean; visId: string }) => {
  const visEntity = useV2Visualization(visId);

  if (!visEntity) return null;

  const vis = visEntity.value;

  const handleTitleChange = (title: string) => {
    visEntity.update({ ...vis, title });
  };
  const title = visEntity?.value.title;
  if (!visEntity) return null;
  if (disabled)
    return (
      <Text size="lg" weight={"semi"}>
        {title}
      </Text>
    );
  return (
    <div className="my-1 flex-1">
      <Input
        disabled={disabled}
        value={title}
        inputClassName="border-none hover:ring-border hover:ring-1 shadow-none hover:shadow text-lg font-semibold max-w-[600px]"
        onChange={(e) => handleTitleChange(e.target.value)}
      />
    </div>
  );
};

// const Icon = () => {
//   return <FileTreeIcon type="visualization" size="lg" />;
// };

const Icon = ({ visId }: { visId: string }) => {
  const viz = useVisualization(visId);
  const type = viz?.value.config.inputs.type;
  if (type) {
    return <VizIcon type={type} isActive={true} />;
  }
  return <FileTreeIcon type="visualization" size="lg" />;
};

const V2Icon = ({ visId }: { visId: string }) => {
  const viz = useV2Visualization(visId);
  if (!viz) return null;
  const iconType = getV3TypeFromLegacyChartType(viz.value.chartType);
  return <VizIcon type={iconType} isActive={true} />;
};

const Owner = ({ visId }: { visId: string }) => {
  const viz = useVisualization(visId);
  const owner = useProfile(viz?.value.profileId);
  if (!viz) return null;
  if (!owner) return null;
  return (
    <Link to={owner.profileUrl!} aria-label="user-link" className="mr-6 flex items-center gap-x-2 px-2">
      <Avatar size="sm" src={owner.avatarUrl!} variant="circle" alt={owner.name} />
      <Text color="muted">{owner.name}</Text>
    </Link>
  );
};

const V2Owner = ({ visId }: { visId: string }) => {
  const viz = useV2Visualization(visId);
  const owner = useProfile(viz?.value.profileId);
  if (!viz) return null;
  if (!owner) return null;
  return (
    <Link to={owner.profileUrl!} aria-label="user-link" className="mr-6 flex items-center gap-x-2 px-2">
      <Avatar size="sm" src={owner.avatarUrl!} variant="circle" alt={owner.name} />
      <Text color="muted">{owner.name}</Text>
    </Link>
  );
};

export const Options = ({
  visId,
  customTrigger,
  trackClone,
  trackDelete,
}: {
  visId: string;
  customTrigger?: React.ReactNode;
  trackClone?: () => void;
  trackDelete?: () => void;
}) => {
  const navigate = useNavigate();
  const viz = useVisualization(visId);
  if (!viz) return null;

  const handleCloneViz = async () => {
    const newViz = await visualizationFactory.clone(visId);
    trackClone?.();
    navigate($path("/studio/queries/:id/visualizations/:vizId", { id: viz.value.queryId, vizId: newViz.id }));
  };

  const handleDeleteViz = async () => {
    await viz.delete();
    trackDelete?.();
    navigate($path("/studio/queries/:id", { id: viz.value.queryId }));
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        {customTrigger || (
          <Button variant="secondary" iconOnly size="sm">
            <MoreVerticalIcon className=" size-5 text-content/50 flex-shrink-0" />
          </Button>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content className="z-50">
        <Dropdown.Item asChild>
          <button className="flex w-full items-center" onClick={handleCloneViz}>
            <CopyIcon className="text-muted-foreground size-4 mr-3" /> Clone Visualization
          </button>
        </Dropdown.Item>
        <Dropdown.Item asChild>
          <ConfirmDialog
            title={`Delete Visualization`}
            onConfirm={handleDeleteViz}
            description={`Are you sure you want to remove ${viz.value.config.options.title?.text} and all its contents?`}
            ref={{ current: null }}
          >
            <Button variant="ghost" className="w-full justify-start">
              <TrashIcon className="text-muted-foreground size-4 mr-3" /> Delete Visualization
            </Button>
          </ConfirmDialog>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export const V2Options = ({ visId, customTrigger }: { visId: string; customTrigger?: React.ReactNode }) => {
  const navigate = useNavigate();
  const viz = useV2Visualization(visId);
  if (!viz) return null;

  const handleCloneViz = async () => {
    const newViz = await v2VisualizationFactory.clone(visId);
    navigate($path("/studio/queries/:id/visualizations/v2/:vizId", { id: viz.value.queryId, vizId: newViz.id }));
  };
  const handleDeleteViz = async () => {
    await viz.delete();
    navigate($path("/studio/queries/:id", { id: viz.value.queryId }));
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        {customTrigger || (
          <Button variant="secondary" iconOnly size="sm">
            <MoreVerticalIcon className=" size-5 text-content/50 flex-shrink-0" />
          </Button>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content className="z-50">
        <Dropdown.Item asChild>
          <button className="flex w-full items-center" onClick={handleCloneViz}>
            <CopyIcon className="text-muted-foreground size-4 mr-3" /> Clone Visualization
          </button>
        </Dropdown.Item>
        <Dropdown.Item asChild>
          <ConfirmDialog
            title={`Delete Query`}
            onConfirm={handleDeleteViz}
            description={`Are you sure you want to remove ${viz.value.title} and all its contents?`}
            ref={{ current: null }}
          >
            <Button variant="ghost" className="w-full justify-start">
              <TrashIcon className="text-muted-foreground size-4 mr-3" /> Delete Visualization
            </Button>
          </ConfirmDialog>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export const VisualizationToolbar = { Root, Title, V2Title, Icon, V2Icon, Owner, V2Owner, Options, V2Options };
